<template>
  <div class="flex  flex-col gap-3.5 rounded-4.5 bg-white ring-inset ring-1 ring-#f1f1f1 shadow-base shadow-black/8 px-6 py-5">
    <div class="flex flex-row justify-between">
      <div class="flex flex-row gap-2">
        <span
          class="text-white px-3 rounded-1.5 py-1.75 text-2.75 bg-#D3E221"
        >{{ isNew ? 'Новый' : order.status.description }}</span>
        <div
          class="flex flex-row gap-0.75  py-1.25 items-center  px-1.5 text-2.75 rounded-1.5 bg-white text-tekstovyy border-0.25 border-black/5"
          @click="isFullOrderOpened = !isFullOrderOpened"
        >
          <p class="leading-0.8 p-0 m-0">{{ isFullOrderOpened ? 'Свернуть' : 'Развернуть' }}</p>
          <span
            class="i-custom:arrow-down min-w-3.5 min-h-3.5 transition-200"
            :class="{'rotate-180' : isFullOrderOpened}"
          ></span>
        </div>
      </div>
      <div class="flex flex-row gap-2">
        <span
          class="i-custom:printer p-2 rounded-1 bg-#EEF7E7 text-firmennyy block text-6"
          @click="$emit('print')"
        ></span>
      </div>
    </div>
    <div class="flex flex-row justify-between items-end">
      <div class="flex flex-col gap-1.5">
        <p class="text-tekstovyy font-700 text-5">{{ `№${order.id}` }}</p>
        <p class="text-seryy text-3.75">
          <span class="font-700">Cоздан: {{ baseTempoFormat(order.created_at, 'YYYY-MM-DD hh:mm') }}</span>
        </p>
        <p class="text-seryy text-3.75">
          <span class="font-700">{{ selectedRestaurant?.title }}</span>
          {{ selectedRestaurant?.additional_param.address }}
        </p>
      </div>
      <div class="flex flex-row justify-between gap-2 items-end">
        <Button
          v-if="order.status.id < 3"
          class="grow"
          :size="'sm'"
          @click="$emit('change-status', {order_id: order.id, status: 3})"
        >
          Принять заказ на {{ order.sum }}
        </Button>
        <Button
          v-if="order.status.id === 3"
          class="grow bg-#FF8C00!"
          :size="'sm'"
          @click="$emit('change-status', {order_id: order.id, status: 4})"
        >
          Изменить статус - в пути
        </Button>
        <Button
          v-if="order.status.id === 4"
          class="grow bg-#1E90FF!"
          :size="'sm'"
          @click="$emit('change-status', {order_id: order.id, status: 5})"
        >
          Изменить статус - доставлен
        </Button>
        <Button
          class="grow max-w-40"
          color="green-light"
          size="sm"
          @click="$emit('open-modal', order.id)"
        >
          Отказаться
        </Button>
      </div>
    </div>
    <div
      v-show="isFullOrderOpened"
      class="flex flex-col gap-4 border-[rgba(0,0,0,0.07)] border-t-0.25 "
    >
      <div class="grid grid-cols-2 gap-10">
        <div class="flex flex-col border-t border-black/7 pt-4 text-tekstovyy text-3.5 leading-1.2 gap-2.5">
          <div
            v-if="order.order_delivery"
            class="grid grid-cols-[236px_1fr]"
          >
            <p class="font-500">Доставить к {{ order.order_delivery.time ? order.order_delivery.time : 'Время не указано' }}</p>
          </div>
          <div class="grid grid-cols-[236px_1fr]">
            <p>Оплата</p>
            <p class="font-500 w-full">{{ order.payment_type.description }}</p>
          </div>
          <div class="grid grid-cols-[236px_1fr]">
            <p>Пользователь</p>
            <p class="flex items-center gap-1.5 font-500">
              <span class="text-firmennyy">{{ order.user.phone }}</span>
              <span class="text-firmennyy">  {{ order.user.name }}</span>
              <span
                class="text-5 text-seryy i-custom:copy cursor-pointer"
                @click="copy(order.user.phone)"
              ></span>
            </p>
          </div>
          <div class="grid grid-cols-[236px_1fr]">
            <p>Адрес доставки</p>
            <p class="flex items-center gap-1.5 font-500">
              <span
                v-if="order.order_delivery"
                class="text-#338cde hover:cursor-pointer"
                @click="copy(deliveryString)"
              >{{ deliveryString }}
              </span>
              <span
                v-else
                class="text-#338cde"
              >Самовывоз</span>

              <span
                class="text-5 min-w-5 min-h-5 text-seryy i-custom:copy cursor-pointer"
                @click="copy(deliveryString)"
              ></span>
            </p>
          </div>
        </div>
        <div
          v-if="order.order_additional_param || order.order_delivery"
          class="flex flex-col border-t border-black/7 pt-4 gap-1.25"
        >
          <div
            v-if="order.order_additional_param"
            class="grid grid-cols-[236px_1fr] items-center text-tekstovyy text-3.5 leading-1.2"
          >
            <div class="flex gap-2.25 items-center">
              <div class="py-1 px-1.375 bg-plashka-green rounded-1.5">
                <span class="i-custom:cutlery text-firmennyy block text-4"></span>
              </div>
              Приборы
            </div>
            <p

              class="font-500"
            >
              {{ order.order_additional_param.cutlery_amount }}
            </p>
          </div>
          <div
            v-if="order.order_additional_param"
            class="grid grid-cols-[236px_1fr] items-center text-tekstovyy text-3.5 leading-1.2"
          >
            <div class="flex gap-2.25 items-center">
              <div class="py-1 px-1.375 bg-plashka-green rounded-1.5">
                <span class="i-custom:chats text-firmennyy block text-4"></span>
              </div>
              Комментарий ресторану
            </div>
            <p class="font-500">
              {{ order.order_additional_param.comment
                ? order.order_additional_param.comment: 'Нет комментария' }}
            </p>
          </div>
          <div
            v-if="order.order_delivery"
            class="grid grid-cols-[236px_1fr] items-center text-tekstovyy text-3.5 leading-1.2"
          >
            <div class="flex gap-2.25 items-center">
              <div class="py-1 px-1.375 bg-plashka-green rounded-1.5">
                <span class="i-custom:chats-circle text-firmennyy block text-4"></span>
              </div>
              Комментарий курьеру
            </div>
            <p class="font-500 text-seryy">
              {{ order.order_delivery.comment
                ? order.order_delivery.comment : 'Нет комментария' }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="isFullOrderOpened"
      class="flex flex-col gap-2.5"
    >
      <template v-for="item in order.order_products">
        <div
          class="flex justify-between items-end pt-2 border-t border-black/7"
        >
          <div class="flex flex-col gap-1">
            <p class="text-seryy text-2.75 font-500">{{ item.product.categories[0].title }}</p>
            <p class="text-tekstovyy text-3.5 font-500 leading-1.2">
              {{ item.count }} × {{ item.product.title }}
              <span class="text-seryy font-400">{{ item.product.size_title }} </span>
            </p>
          </div>
          <p class="text-tekstovyy text-3.5 font-500 leading-1.2">{{ item.sum }}₽</p>
        </div>
      </template>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useRestaurants } from '~/composables/partner/restaurants/useRestaurants';
import type { OrderDataType } from '~/composables/partner/orders/types/PartnerOrdersTypes';
const { selectedRestaurant } = useRestaurants();
const props = defineProps<{
    order: OrderDataType
    isNew: boolean;
}>();
const { copy, text } = useClipboard();

defineEmits(['open-modal', 'change-status', 'print']);
const isFullOrderOpened = ref(true);

const deliveryString = computed(() => {
  const { address, entrance, flat, doorphone } = props.order.order_delivery;

  const parts = [
    address || 'Самовывоз',
    entrance ? `Подъезд ${entrance}` : '',
    flat ? `Квартира ${flat}` : '',
    doorphone ? `Домофон ${doorphone}` : ''
  ];

  return parts.filter(Boolean).join(', ');
});
</script>
