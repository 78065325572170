<template>
  <div>
    <div class="flex flex-row justify-between">
      <h2 class="text-tekstovyy text-7 font-700 leading-0.8">Новые заказы</h2>
      <Dropdown
        v-if="orderStatusesRef"
        :title="'Фильтрация по статусам'"
        :ui="{headerContent:'flex gap-2 items-center px-3 py-1.25 text-2.75 text-white font-700 -tracking-0.01em rounded-1.5 bg-#2185e2', wrapper: 'ring-#f1f1f1 ring-inset ring-1 shadow-base shadow-black/8 bg-white p-2 flex flex-col absolute rounded-4.5 top-full left-0 translate-y-1.75 z-1', root: 'relative' }"
      >
        <template #dropdown-header-icon>
          <span class="text-3.5 i-custom:arrow-down"></span>
        </template>
        <template v-for="item in orderStatusesRef">
          <div
            class="uno-3qtl1i"
            @click="handleFilter(item.id)"
          >
            <span class="i-custom:chef-hat text-6 transition-colors"></span>
            {{ item.description }}
          </div>
        </template>
      </Dropdown>
    </div>
    <div class="border-t border-black/5 pt-5 mt-6">
      <template
        v-for="order in showedWorkOrdersRef"
        :key="order.id"
      >
        <OrderFullCard
          class="mt-2"
          :order="order"
          :is-new="order.status.id < 3"
          @open-modal="(value) => handleOpenModal(Number(value))"
          @change-status="(value) => { orders.changeOrderStatus(value).then(() => getWorkOrdersToRef())}"
          @print="handlePrint(order)"
        ></OrderFullCard>
      </template>
      <!-- <div class="grid grid-cols-[246px_1fr] gap-6 items-start mt-3">
        <div class="flex flex-col gap-2.5">
          <template
            v-for="order in inWorkOrdersRef"
            :key="order.id"
          >
            <OrderSmallCard
              :id="order.id"
              :is-selected="activeCard!.id === order.id"
              :price="order.sum"
              :is-new="isNew(order)"
              :description="'Доставка из ресторана'"
              @click="{activeCard = order; orderStore.deleteOrderInNew(order)}"
            />
          </template>
        </div>
        <OrderDetailCard
          v-if="activeCard"
          :active-card="activeCard"
          :selected-restraunt="selectedRestaurant!"
          @click="orderStore.deleteOrderInNew(activeCard!)"
          @change-status="(value) => { orders.changeOrderStatus(value).then(() => getWorkOrdersToRef()) }"
          @update-list="getWorkOrdersToRef()"
        />
      </div> -->
      <!-- {{ ordersList }} -->
      <Receipt
        v-if="orderToPrint"
        ref="receiptContent"
        :order-data="orderToPrint!"
      />
    </div>
    <TransitionModal>
      <LazyDeclineReasonModal
        v-if="isModalShow"
        :order_id="modalId"
        @close="closeModal"
        @update-list="getWorkOrdersToRef()"
      />
    </TransitionModal>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onBeforeMount } from 'vue';
import { usePartnerOrders } from '~/composables/partner/orders/usePartnerOrders';
import { useRestaurants } from '~/composables/partner/restaurants/useRestaurants';
import type { OrderDataType, ChangeOrderStatusRequestType } from '~/composables/partner/orders/types/PartnerOrdersTypes';
import { userStore as store } from '#imports';
import { orderStore as useOrderStore } from '~/stores/order-store';
const { closeModal, isModalShow, openModal } = useModal();
const handleOpenModal = (id: number) => {
  modalId.value = id;
  openModal();
};
const receiptContent = ref(null);

// const printReceipt = () => {
//   const printContents = receiptContent.value.$el.innerHTML;
//   const originalContents = document.body.innerHTML;

//   document.body.innerHTML = printContents;
//   window.print();
//   document.body.innerHTML = originalContents;
//   location.reload(); // Перезагрузка страницы, чтобы восстановить исходное состояние DOM
// };

const orderToPrint = ref<OrderDataType | null>(null);
const handlePrint = (order: OrderDataType) => {
  orderToPrint.value = order;
  setTimeout(() => {
    // @ts-ignore
    const printContents = receiptContent.value.$el.innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    // @ts-ignore
    document.body.style = 'width: 7.5cm';
    window.print();
    document.body.innerHTML = originalContents;
    location.reload(); // Перезагрузка страницы, чтобы восстановить исходное состояние DOM
  }, (1000));
  // @ts-ignore
};

const modalId = ref(0);
const activeCard = ref<OrderDataType | null>(null);
const userStore = store();
const { isUserLoggedIn } = storeToRefs(userStore);
const orders = usePartnerOrders();
const { selectedRestaurant } = useRestaurants();
const inWorkOrdersRef = ref<OrderDataType[] | []>([]);
const showedWorkOrdersRef = ref<OrderDataType[] | []>([]);
const orderStore = useOrderStore();
const orderStatusesRef = ref<{id: number, description: string}[] | null>(null);

const getWorkOrdersToRef = async () => {
  if (selectedRestaurant.value) {
    const inWorkOrders = await orders.getInWorkOrders(selectedRestaurant.value!.id);
    const flatOrders = inWorkOrders?.flatMap((item) => item.list) || [];
    const orderStatuses = orders.getOrderStatuses();
    orderStatusesRef.value = await orderStatuses;
    inWorkOrdersRef.value = flatOrders;
    showedWorkOrdersRef.value = flatOrders;
    orderStore.setOrders(flatOrders);
    console.log(orderStore.orders);

    if (inWorkOrdersRef.value.length > 0) {
      activeCard.value = orderStore.orders[0];
    }
    cleanUpNewOrders();
  }
};

function filterOrdersByStatus (statusId: number) {
  // Проверка на валидность входных данных
  console.log(typeof statusId);
  if (typeof statusId !== 'number') {
    console.error('Invalid status ID: must be a number');
    return null;
  }

  // Фильтрация значений в ref
  showedWorkOrdersRef.value = inWorkOrdersRef.value.filter((order) => {
    // Проверка на наличие и валидность `status` и `status.id`
    return order.status && typeof order.status.id === 'number' && order.status.id === statusId;
  });
}

const handleFilter = (id: number) => {
  const filtred = filterOrdersByStatus(id);
  if (filtred) {
    showedWorkOrdersRef.value = filtred;
  }
};

const cleanUpNewOrders = () => {
  const currentOrderIds = new Set(inWorkOrdersRef.value?.map((order) => order.id));
  orderStore.newOrders = orderStore.newOrders.filter((order_id) => currentOrderIds.has(order_id));
};

// watch(() => orderStore.orders, (oldOrders, newOrders) => {
//   console.log(oldOrders.length, newOrders.length);
//   if (orderStore.orders.length !== inWorkOrdersRef.value.length) {
//     inWorkOrdersRef.value = orderStore.orders;
//   }
// });

// watch(() => newOrdersComputed.value, () => {
//   const uvedAudio = new Audio('/sound/uved.mp3');
//   uvedAudio.play();
// }, {
//   immediate: false
// });

// onBeforeMount(() => {
//   getWorkOrdersToRef();
// });

watch(selectedRestaurant, () => {
  console.log(orderStore.newOrders);
  getWorkOrdersToRef();
}, { immediate: true });
</script>
